.LoginSignup{
    padding: 30px 0px;
    background-color: #1c232b;
}

.loginLeftCol{
    padding: 50px 30px 0px 30px;
    border-radius: 30px;
    background: #FFFFFF14;
}

.textBoxLogin{
    padding: 30px;
    margin-bottom: 100px;
    border-radius: 20px;
    background: rgb(255 255 255 / 20%);
    box-shadow: 0px 4px 17px 0px #00000040;
}

.textBoxLogin h4{
    font-family: 'Akaya Kanadaka';
    font-size: 25px;
    font-weight: 400;
    line-height: 36px;
    color: #fff;
    margin-bottom: 15px;
}

.textBoxLogin p{
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    color: #fff;
    margin-bottom: 0;
}

.loginRightCol{
    padding: 20px 50px;
}

.loginRightCol h2{
    font-family: 'Quicksand', sans-serif;
    color: #fff;
    font-size: 46px;
    font-weight: 700;
    line-height: 60px;
    margin-bottom: 10px;
}

.loginRightCol p{
    font-family: 'Quicksand', sans-serif;
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
}

.loginRightCol ul.nav.nav-tabs {
    border: none;
    padding: 3px;
    border-radius: 50px;
    margin-bottom: 30px;
    background: rgb(255 255 255 / 10%);
}

.loginRightCol ul.nav.nav-tabs .nav-item {
    width: 50%;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    max-width: 50%;
    text-align: center;
}

.loginRightCol ul.nav.nav-tabs .nav-item button{
    width: 100%;
    max-width: 100%;
    color: #fff;
    text-align: center;
    display: inline-block;
}

.loginRightCol ul.nav.nav-tabs .nav-item button:focus, .loginRightCol ul.nav.nav-tabs .nav-item button:focus-visible {
    box-shadow: none;
    border: none;
}

.loginRightCol ul.nav.nav-tabs .nav-item .active{
    color: #fff;
    border: none;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    border-radius: 50px;
    background-color: #141B22;
    font-family: 'Quicksand', sans-serif;
    box-shadow: 0px 0px 4px 0px #EC22281A;
}

.loginRightCol ul.nav.nav-tabs .nav-item button:hover{
    border: none;
    isolation: inherit;
}

.userLoginInput{
    margin-bottom: 20px;
}

.innerInputBox{
    position: relative;
}

.userLoginInput input {
    font-family: 'Quicksand', sans-serif;
    background-color: #141B22;
    border-radius: 100px;
    width: 100%;
    min-width: 304px;
    height: 56px;
    padding: 15px 15px 15px 60px;
    color:#BCBCBC;
    border: 1.5px solid rgb(224 207 191 / 46%);
    box-shadow: 0px 4px 8px 0px #FFFFFF1A;
}

.userLoginInput .inputIcon{
    top: 6px;
    z-index: 9;
    left: 6px;
    position: absolute;
}

.forgotPassBox{
    text-align: right;
    margin-bottom: 30px;
}

.forgotPassBox a{
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #c4c4c4;
}

.signInBtn{
    font-family: 'Akaya Kanadaka';
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    background: #FF8811;
    border-radius: 50px;
    border: none;
    color: #fff;
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
}

.signInBtn:hover{
    background: #d06d0a !important;
}

.otherLoginOpt p{
    margin: 0;
    color: #fff;
    position: relative;
    font-family: Nunito;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
}

.otherLoginOpt p::after{
    position: absolute;
    content: '';
    width: 78px;
    height: 1px;
    left: 15%;
    top: 50%;
    background-color: #ffffff;
}

.otherLoginOpt p::before{
    position: absolute;
    content: '';
    width: 78px;
    height: 1px;
    right: 15%;
    top: 50%;
    background-color: #ffffff;
}

.otherLoginOpt p a{
    color: #FF8811;
    text-decoration: underline !important;
}

.userLoginInput input::placeholder{
    color: #484848;
}

.loginSocialIcon a{
    margin: 0px 5px;
}

.otpInputBox {
    display: flex;
    gap: 20px;
}

.otpInputBox input{
    padding: 0;
    height: 54px;
    min-width: 79px;
    text-align: center;
}

.otpInputBox .ant-form-item{
    margin-bottom:10px;
}

input:-internal-autofill-selected{
    background-color: #141B22;
}