.resultHeaderText {
    color: var(--white, #FFF);
    text-align: center;
    font-family: Quicksand;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;


}

.resultTop {
    width: 100%;
    height: 383px;
    flex-shrink: 0;
    background: url("https://c.animaapp.com/CSoAjsAi/img/rectangle-2.png"), lightgray 0px -120.769px / 100% 250.653% no-repeat;

}

.resultHeader {

    display: flex;
    justify-content: center;
    /* Horizontally center */
    align-items: center;
    /* Vertically center */
    height: 100%;
    /* Ensure the container takes the full height */
}

.resultTable {
    /* width: 1238px;
    height: 62px;
    flex-shrink: 0; */
    background: var(--sec-bg, #141B22);

}