.settingLeftCol {
    background-color: #141B22;
    padding: 50px 30px 0px 30px;
    width: 488px;
    height: 315px;
    flex-shrink: 0;
}

.settingInfo {
    color: #FFF;
    font-family: Akaya Kanadaka;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.settingProfile p {
    color: var(--main, #F81);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.settigRightCol {
    height: auto;
    border-radius: 14px;
    background: linear-gradient(103deg, #27394B 2.37%, rgba(20, 27, 34, 0.43) 30.68%);
}

.h4-custom {
    color: var(--white, #FFF);
    font-family: 'Akaya Kanadaka', sans-serif;
    /* Specify the correct font-family */
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.h6-custom {
    color: #FFF;
    font-family: Quicksand;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.paymentSetting {
    padding: 32px
}

.cardInfo {
    padding: 8px;
    margin-bottom: 19px;
    background: #364250;
    color: #ffff;
}

.cardInfo img {
    width: 28.602px;
    height: 30px;
    flex-shrink: 0;
}