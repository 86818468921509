.contactUs {
    width: 100%;
    height: 383px;
    background: url('../../../Assets/images/contactus.png'), lightgray 0px -120.769px / 100% 250.653% no-repeat;
}

.topContent {

    color: var(--white, #FFF);
    text-align: center;
    font-family: Quicksand;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.contactUsTop {

    display: flex;
    justify-content: center;
    /* Horizontally center */
    align-items: center;
    /* Vertically center */
    height: 100%;
}