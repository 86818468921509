.topPanel{
    background-color:#141B22;
    border-radius: 14px;
    padding:16px 20px;
    margin-bottom: 20px;
}

.topPanel h4{
    margin-bottom: 0;
    color: #fff;
    font-family: 'Akaya Kanadaka';
    font-size: 24px;
}

.userProfile {
    color: #fff;
    font-size: 18px;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
}

.userProfile img{
    width: 43px;
    margin-right: 10px;
    border-radius: 14px;
}

.topNotificationBox{
    gap: 30px;
    display: flex;
    align-items: center;
    justify-content: end;
}