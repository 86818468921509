.vote {
    background-color: #1c232b;
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%; */
}

.topImage {
    width: 100%;
}

.vote .div {
    background-color: var(--primary-bg);
    height: 1414px;
    overflow: hidden;
    position: relative;
    width: 1440px;
}

.vote .overlap {
    height: 542px;
    left: -48px;
    position: absolute;
    top: 0;
    width: 1530px;
}

.vote .rectangle {
    height: 383px;
    left: 48px;
    object-fit: cover;
    /* position: absolute; */
    top: 99px;
    width: 1440px;
}

.vote .text-wrapper {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 50px;
    font-weight: 700;
    height: 63px;
    left: 481px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 258px;
}

.vote .ellipse {
    background: linear-gradient(180deg, rgb(255, 136, 17) 0%, rgba(28, 35, 43, 0) 100%);
    border-radius: 82px;
    height: 164px;
    left: 1366px;
    position: absolute;
    top: 378px;
    width: 164px;
}

.vote .ellipse-2 {
    background: linear-gradient(180deg, rgb(255, 136, 17) 0%, rgba(28, 35, 43, 0) 100%);
    border-radius: 48.49px;
    height: 97px;
    left: 18px;
    position: absolute;
    top: 313px;
    transform: rotate(120deg);
    width: 97px;
}

.vote .group {
    background-color: #1c232b;
    height: 100px;
    left: 48px;
    position: absolute;
    top: 0;
    width: 1440px;
}

.vote .img {
    height: 50px;
    left: 1290px;
    object-fit: cover;
    position: absolute;
    top: 25px;
    width: 50px;
}

.vote .logo-without-bg {
    height: 50px;
    left: 100px;
    position: absolute;
    top: 25px;
    width: 35px;
}

.contestantEmailBox {
    height: 44px;
    width: 329px;
}

.contestantEmail {
    background-color: #1c75ce1a;
    border: 1px solid;
    border-color: transparent;
    border-image: linear-gradient(to bottom, rgb(218, 201, 184), rgba(218, 201, 184, 0)) 1;
    border-radius: 50px;
    height: 44px;
    left: 0;
    position: relative;
    top: 0;
    width: 329px;
}

.vote .navbar {
    height: 29px;
    left: 510px;
    position: absolute;
    top: 39px;
    width: 444px;
}

.vote .rectangle-2 {
    background-color: var(--main);
    border-radius: 15px;
    height: 2px;
    left: 166px;
    position: absolute;
    top: 27px;
    width: 57px;
}

.vote .text-wrapper-2 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.vote .text-wrapper-3 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 82px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.vote .text-wrapper-4 {
    color: var(--main);
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 600;
    left: 166px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.vote .text-wrapper-5 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 254px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.vote .text-wrapper-6 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 341px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.vote .footer {
    background-color: transparent;
    height: 359px;
    left: 0;
    position: absolute;
    top: 1055px;
    width: 1452px;
}

.vote .overlap-group {
    background-color: #141b22;
    height: 359px;
    position: relative;
    width: 1440px;
}

.vote .logo-without-bg-2 {
    height: 50px;
    left: 100px;
    position: absolute;
    top: 100px;
    width: 35px;
}

.vote .login-register {
    color: #ffff;
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 573px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 147px;
}

.vote .text-wrapper-7 {
    color: var(--main);
    font-family: "DM Sans", Helvetica;
    font-size: 18px;
    font-weight: 700;
    left: 573px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 100px;
}

.vote .p {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 100px;
    letter-spacing: 0;
    line-height: 22.4px;
    position: absolute;
    top: 166px;
    width: 333px;
}

.vote .facebook {
    height: 36px;
    left: 1252px;
    position: absolute;
    top: 100px;
    width: 36px;
}

.vote .instagram {
    background-color: #1c232b;
    border-radius: 18px;
    height: 36px;
    left: 1304px;
    position: absolute;
    top: 100px;
    width: 36px;
}

.vote .instagram-icon {
    height: 18px;
    left: 9px;
    position: absolute;
    top: 9px;
    width: 18px;
}

.vote .about-us-press {
    color: #ffff;
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 914px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 147px;
}

.vote .text-wrapper-8 {
    color: var(--main);
    font-family: "DM Sans", Helvetica;
    font-size: 18px;
    font-weight: 700;
    left: 914px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 100px;
}

.vote .text-wrapper-9 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 1193px;
    letter-spacing: 0;
    line-height: 22.4px;
    position: absolute;
    top: 155px;
    white-space: nowrap;
}

.vote .group-2 {
    height: 36px;
    left: 1149px;
    position: absolute;
    top: 100px;
    width: 36px;
}

.vote .vector-wrapper {
    background-color: #1c232b;
    border-radius: 18px;
    height: 36px;
    left: 1201px;
    position: absolute;
    top: 100px;
    width: 36px;
}

.vote .vector {
    height: 19px;
    left: 8px;
    position: absolute;
    top: 9px;
    width: 20px;
}

.vote .group-3 {
    height: 373px;
    left: 314px;
    position: absolute;
    top: 582px;
    width: 819px;
}

.vote .overlap-group-wrapper {
    height: 50px;
    left: 431px;
    position: absolute;
    top: 323px;
    width: 331px;
}

.vote .div-wrapper {
    background-color: #ff8811;
    border-radius: 50px;
    box-shadow: 0px 0px 50px #3c36f140;
    height: 50px;
    position: relative;
    width: 329px;
}

.vote .text-wrapper-10 {
    color: #ffff;
    font-family: "Akaya Kanadaka", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 143px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 11px;
}

.vote .text-wrapper-11 {
    color: var(--main);
    font-family: "Quicksand", Helvetica;
    font-size: 16px;
    font-weight: 500;
    left: 431px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 91px;
}

.vote .overlap-wrapper {
    height: 44px;
    left: 431px;
    position: absolute;
    top: 131px;
    width: 331px;
}

.vote .overlap-2 {
    background-color: #141b221a;
    border-radius: 50px;
    height: 44px;
    position: relative;
    width: 329px;
}

.vote .img-wrapper {
    background-color: #ffffff;
    border-radius: 17px;
    box-shadow: inset 0px 4px 14px #0000001a;
    height: 34px;
    left: 6px;
    position: absolute;
    top: 5px;
    width: 34px;
}

.vote .vector-2 {
    height: 14px;
    left: 12px;
    position: absolute;
    top: 10px;
    width: 11px;
}

.vote .text-wrapper-12 {
    color: #bcbcbc;
    font-family: "Quicksand", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 55px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 13px;
}

.vote .group-4 {
    height: 60px;
    left: 423px;
    position: absolute;
    top: 255px;
    width: 345px;
}

.vote .group-5 {
    height: 60px;
    left: 423px;
    position: absolute;
    top: 191px;
    width: 345px;
}

.vote .text-wrapper-13 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    height: 23px;
    left: 431px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 58px;
}

.vote .rectangle-3 {
    height: 373px;
    left: 0;
    object-fit: cover;
    /* position: absolute; */
    top: 0;
    width: 373px;
}

.vote .text-wrapper-14 {
    color: #ffff;
    font-family: "Akaya Kanadaka", Helvetica;
    font-size: 40px;
    font-weight: 400;
    height: 48px;
    left: 431px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: right;
    top: 0;
    white-space: nowrap;
}

.fullName {
    border-radius: 50px;
    border: 1px solid var(--field, #DAC9B8);
    background: rgba(20, 27, 34, 0.10);
    width: 329px;
    height: 62px;
    flex-shrink: 0;
}

input#fullName {

    color: #BCBCBC;
    font-family: Quicksand;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.voteButton {
    border-radius: 50px;
    background: var(--main, #F81);
    width: 329px;
    height: 50px;
    flex-shrink: 0;
    transition: box-shadow 0.3s;
    /* Add a transition for smooth hover effect */
}

.voteButton:hover {
    box-shadow: 0px 0px 50px 0px rgba(61, 55, 241, 0.25);
    /* Apply box shadow on hover */
}

/* App.css */
.placeholder-input {
    color: #BCBCBC;
    font-family: Quicksand;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.voteName {
    color: var(--white, #FFF);
    /* text-align: right; */
    font-family: Akaya Kanadaka;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.voteCategory {

    color: var(--white, #FFF);
    /* text-align: right; */
    font-family: Quicksand;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.voteCode {
    color: var(--main, #F81);
    font-family: Quicksand;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.bgTop {
    background: linear-gradient(180deg, #1C232B 0%, rgba(28, 35, 43, 0.00) 179.92%), url(https://c.animaapp.com/D2VowqH2/img/rectangle-2.png);
    background-color: lightgray;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 383px;
    /* Set the height to fill the viewport */
}

.categoryHeading {
    color: #fff;
    text-align: center;
    top: 50%;
    font-family: Quicksand;
    font-size: 50px;
    font-style: normal;
    padding: 114px;
    font-weight: 700;
    line-height: normal;
}

.constestantImg {

    width: 373px;
    height: 373px;
    flex-shrink: 0;
    border-radius: 10px;
    /* background: url(<path-to-image>), lightgray 50% / cover no-repeat; */



}

.containerForm {
    padding: 50px;
}

@media (max-width: 768px) {
    .constestantImg {
        width: 277px;
        /* height: 373px; */
        object-fit: contain;
    }

}