@import url('https://fonts.googleapis.com/css2?family=Akaya+Kanadaka&family=DM+Sans:opsz,wght@9..40,300;9..40,400;9..40,500&family=Quicksand:wght@300;400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
}

.sectionPadding {
  padding: 80px 0px 0px;
}

.padding-bottom-120 {
  padding-bottom: 120px;
}

.padding-top-95 {
  padding-top: 95px;
}

.padding-bottom-80 {
  padding-bottom: 80px;
}

.padding-bottom-50 {
  padding-bottom: 50px;
}

a {
  text-decoration: none !important;
  font-family: 'Quicksand', sans-serif;
  cursor: pointer;
}

a:focus-visible {
  outline: none;
}

.mobileNav {
  display: none;
}

.mobileNav {
  background: #1C232B;
  padding: 10px 15px;
}

.navBarTop {
  background: #1C232B;
}

.logo {
  max-width: 200px;
  cursor: pointer;
}

.mobile-toggle-icon {
  text-align: right;
}

.mobile-toggle-icon img {
  width: 30px;
}

.topBar .nav {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.head-top-links {
  display: flex;
  align-items: center;
  gap: 10px;
}

.head-top-links a {
  color: #fff;
  text-decoration: none;
  display: flex;
  gap: 10px;
  font-weight: 500;
  font-size: 16px;
}

.head-top-links a div p {
  margin: 0;
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}

.logoBox {
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  padding: 0;
  margin: 0;
}

.topBar .bg-body-tertiary {
  background-color: transparent !important;
}

.topBar .navbar-nav {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.topBar .navbar-nav a {
  padding: 0;
  color: #fff;
  margin: 0 15px;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  text-transform: capitalize;
}

.topBar .navbar-nav a:hover {
  color: #FF8811;
}

.loginBtn {
  border: none;
  color: #1C232B;
  font-size: 24px;
  font-weight: 400;
  margin: 0px 5px;
  padding: 6px 20px;
  line-height: 29px;
  background: transparent;
  font-family: 'Akaya Kanadaka';
}

.login-register-box {
  background: #fff;
  padding: 5px 0px;
  border-radius: 50px;
}

.navbar-nav .active {
  color: #FF8811 !important;
  position: relative;
}

.navbar-nav .active::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -5px;
  width: 85%;
  height: 2px;
  background: #f81;
  margin: auto;
}

.activeBtn {
  color: #fff;
  border-radius: 30px;
  padding: 6px 30px;
  background: #FF8811;
}

.languageDropDown {
  font-family: 'Quicksand', sans-serif;
  color: #fff;
  font-weight: 800;
  border: none;
  outline: none;
  background: transparent;
}

.languageDropDown option {
  color: #00416f;
}

.carousel-item img {
  width: 100%;
  height: 600px;
}

.carousel-control-next,
.carousel-control-prev {
  display: none !important;
}

.carousel-indicators [data-bs-target] {
  height: 10px !important;
  width: 10px !important;
}

.carousel-indicators .active {
  background-color: #FF8811 !important;
}

.carousel-item .carousel-caption {
  right: 0;
  left: 0;
  bottom: auto;
  top: 25%;
}

.carousel-item .carousel-caption h3 {
  font-family: 'Quicksand', sans-serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 63px;
  text-align: center;
}

.carousel-item .carousel-caption p {
  font-family: 'Quicksand', sans-serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 38px;
  letter-spacing: 0em;
  margin-bottom: 50px;
  text-align: center;
}

.loadingPage {
  background-color: #1C232B;
  width: 100vw;
  height: 100vh;
  text-align: center;
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingPage img {
  width: 100px;
}

.sliderSearch {
  height: auto !important;
  width: 390px !important;
}

.contestantFilter {
  margin: auto;
  padding: 5px;
  max-width: 389px;
  min-height: 50px;
  border-radius: 100px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
}

.contestantFilter .dropdown {
  font-family: 'Akaya Kanadaka';
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
}

.contestantFilter .dropdown .btn-success {
  border: none;
  min-width: 120px;
  min-height: 40px;
  border-radius: 28px;
  background-color: #FF8811;
  position: relative;
  z-index: 9;
}

.contestantFilter input.form-control {
  left: -50px;
  border: none;
  min-height: 40px;
  min-width: 310px;
  border-radius: 50px;
  position: relative;
  padding-left: 80px;
  padding-right: 40px;
  background: #ebe3da;
  background: linear-gradient(90deg, #DAC9B8 0%, rgba(218, 201, 184, 0) 100%);
}

.bannerSrchBtn {
  background-color: transparent !important;
  border: none !important;
  padding: 0 !important;
}

.bannerSrchBtn {
  position: absolute;
  top: 5px;
  right: 5px;
}

.bannerSrchBtn img {
  height: 40px;
  width: 40px;
}

.ongoingEventSection {
  position: relative;
  background-color: #1c232b;
}

.heading {
  text-align: center;
  color: #fff;
  font-family: 'Akaya Kanadaka';
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  margin-bottom: 50px;
}

.eventBox {
  padding: 0;
  border-radius: 19px;
  position: relative;
  z-index: 9;
  margin-bottom: 30px;
  min-height: 365px;
  background-color: #141B22;
  transition: all ease-in 0.25s;
  cursor: pointer;
}

.eventBox:hover {
  box-shadow: 0px 6px 50px 0px #3D37F133;
}

/* .eventBox img {
  width: 100%;
  min-height: 197px;
  max-height: 250px;
  /* height added */


.eventBox img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
}


.frontEndOngingEvent .eventBox {
  min-height: 365px !important;
}

.contestantBox {
  top: 20px;
  left: 20px;
  max-width: 115px;
  padding: 4px 9px;
  position: absolute;
  border-radius: 10px;
  background: #FFFFFF80;
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 50%);
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 50%);

  font-family: 'Akaya Kanadaka';
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #141B22;
}

.eventDtls {
  gap: 15px;
  color: #fff;
  display: flex;
  padding: 15px 30px;
}

.dateBox {
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  font-family: 'Quicksand', sans-serif;
}

.dateBox span {
  display: block;
  font-size: 11px;
  font-weight: 700;
  color: #FF8811;
  line-height: 14px;
  text-align: center;
  font-family: 'Quicksand', sans-serif;
}

.eventDtls h5 {
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.eventDtls p {
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
}

.vector1Img {
  position: absolute;
  right: 0;
  bottom: 0;
}

.themeBtn {
  color: #fff;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
  padding: 11px 47px;
  margin-bottom: 5px;
  border-radius: 50px;
  display: inline-block;
  background-color: #FF8811;
  font-family: 'Akaya Kanadaka';
}

.upcoming-events-box {
  position: relative;
}

.upcoming-events-box img {
  width: 100%;
  height: 290px;
  padding: 10px;
  object-fit: cover;
  position: relative;
  border-radius: 30px;
}

.upcoming-events-box .serThumbTxt {
  top: 45%;
  z-index: 99;
  width: 100%;
  padding: 5px;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  position: absolute;
  font-family: 'Quicksand', sans-serif;
  background-color: rgb(20 27 34 / 36%);
}

.aboutSection {
  background: #141B22;
}

.aboutBox img {
  width: 100%;
}

.aboutBox p {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  font-family: 'Quicksand', sans-serif;
}

.makeEventSection {
  padding: 15px 0px 5px;
  background: rgb(255, 233, 212);
  background: linear-gradient(0deg, rgba(255, 233, 212, 1) 0%, rgba(255, 233, 212, 1) 76%, rgba(28, 35, 43, 1) 59%, rgba(28, 35, 43, 1) 100%);
}

.eventsDtls {
  padding-left: 50px;
  margin-bottom: 30px;
}

.eventsDtls h4 {
  font-family: 'DM Sans', sans-serif;
  font-size: 34px;
  font-weight: 700;
  line-height: 44px;
  margin-bottom: 20px;
}

.eventsDtls p {
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  color: #414141;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 30px;
}

.blackBtn {
  background-color: #1D242C;
}

.mettingImg {
  position: relative;
  bottom: -30px;
}

.vector2Img {
  left: 0;
  bottom: 0;
  width: 300px;
  height: 300px;
  position: absolute;
  transform: rotate(180deg);
}

.voteBox {
  color: #fff;
  width: 100%;
  min-height: 150px;
  position: relative;
  margin-bottom: 30px;
  border-radius: 100px;
  background: linear-gradient(0deg, #141B22, #141B22),
    linear-gradient(180deg, #FFE9D4 0%, rgba(255, 233, 212, 0) 100%);
  box-shadow: 0px 10px 50px 0px #3D37F140;

  gap: 20px;
  display: flex;
  padding: 30px;
  align-items: center;
  z-index: 9;
}

.voteBox h5 {
  font-family: 'Quicksand', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.voteBox p {
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-bottom: 0px;
}

.voteBtn {
  font-size: 18px;
  line-height: 24px;
  padding: 4px 30px;
  border: none;
  position: absolute;
  right: 4%;
  bottom: 43%;
}

.voteBtn {
  font-size: 18px;
  line-height: 24px;
  padding: 4px 30px;
  border: none;
  position: absolute;
  right: 4%;
  bottom: 43%;
}

.voteBtn {
  font-size: 18px;
  line-height: 24px;
  padding: 4px 30px;
  border: none;
  position: absolute;
  right: 4%;
  bottom: 43%;
}

#footer {
  background-color: #141B22;
}

.inner-footer {
  display: flex;
  gap: 50px;
  justify-content: space-between;
}

.inner-footer .colBox1 {
  width: 33%;
}

.footer-widget {
  color: #fff;
}

.footer-widget p {
  font-family: 'Quicksand', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.footer-widget .head-top-logo {
  margin-bottom: 15px;
}

.footer-widget h4 {
  font-family: 'DM Sans', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 23px;
  color: #FF8811;
  margin-bottom: 30px;
  letter-spacing: 1px;
}

.footer-widget ul {
  margin: 0;
  padding: 0;
}

.footer-widget ul li {
  list-style: none;
  line-height: 26px;
}

.footer-widget ul li a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  font-family: 'DM Sans', sans-serif;
}

.footer-social {
  margin-bottom: 15px;
}

.footer-social a {
  margin-left: 15px;
}

.colBox4 p {
  color: #fff;
}

.copyRight {
  text-align: right;
}

.offcanvas {
  max-width: 85% !important;
  background-color: #1c232b !important;
}

.offcanvas .nav-link {
  color: #fff;
}

nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light {
  background: transparent !important;
}


.eventBanner {
  background: url(Assets/images/event-banner.png);
  background-size: cover;
  background-position: center center;
  padding: 150px 0px;
}

.eventBanner h3 {
  font-family: 'Quicksand', sans-serif;
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  line-height: 63px;
  text-align: center;
  margin-bottom: 15px;
}

.event-search-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  position: relative;
}

.event-search-banner div {
  position: relative;
}

.event-search-banner img {
  position: absolute;
  top: 4px;
  left: 6px;
}

.event-search-banner input {
  background: transparent url(Assets/images/input-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 80px;
  min-width: 270px;
  height: 44px;
  padding: 15px 15px 15px 50px;
  color: #bcbcbc;
  border: none;
  margin-bottom: 30px;
}

.categoryBanner {
  background: url(Assets/images/category-top.png);
  background-size: cover;
  background-position: center center;
  padding: 150px 0px;

}

.categoryBanner h3 {
  font-family: 'Quicksand', sans-serif;
  color: #fff;
  font-size: 50px;
  font-weight: 700;
  line-height: 63px;
  text-align: center;
  margin-bottom: 15px;
}

.categoryBanner p {
  font-family: 'Quicksand', sans-serif;
  color: #fff;
  font-size: 30px;
  font-weight: 300;
  line-height: 63px;
  text-align: center;
  margin-bottom: 15px;
}

.categoryDateContainer {
  text-align: center;

}

.categoryDate {
  display: inline;
  color: var(--main, #F81);
  text-align: center;
  font-family: Akaya Kanadaka;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 50px;
  border: 1px solid var(--main, #F81);
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(6.5px);
  padding: 0px 15px;
}

.categoryDetails {

  border-radius: 0px 0px 20px 20px;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(5px);
  margin-top: auto;
  width: 100%;
}

.categoryDetails h5 {
  color: var(--white, #FFF);
  text-align: center;
  font-family: Quicksand;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

}

.constestantLink {
  color: var(--main, #F81);
  font-family: Akaya Kanadaka;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 11px;
  /* 21px */
  text-decoration-line: underline;
}

.categoryBox {
  padding: 0;
  border-radius: 19px;
  display: grid;
  position: relative;
  z-index: 9;
  margin-bottom: 30px;
  background: url(Assets/images/award.png);
  background-size: cover;
  transition: all ease-in 0.25s;
  cursor: pointer;
  height: 300px;

}

.categoryBox:hover {
  box-shadow: 0px 6px 50px 0px #3D37F133;
}

.addEventDiv {
  display: flex;
  justify-content: end;
}

.icon-image-column {

  max-width: 95px;
  /* Example size constraint */
  max-height: 50px;
  border-radius: 9px;
}

.contactUsForm {
  background: #1C232B;
  color: #fff;
  text-align: center;
  font-family: Akaya Kanadaka;
  font-size: 40px;
  font-style: normal;
}

.contactUsForm p {
  color: var(--white, #FFF);
  font-family: Quicksand;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.read-row {
  background-color: lightgreen;
}

.unread-row {
  background-color: lightcoral;
}

.contactUsFormWrap {
  width: 100%;
  max-width: 650px;
  margin: auto;
}


@media (min-width:767px) and (max-width: 920px) {
  .topBar {
    display: none;
  }

  .mobileNav {
    display: block;
  }
}

@media (min-width:320px) and (max-width: 640px) {

  .padding-top-95,
  .sectionPadding {
    padding-top: 50px;
  }

  .padding-bottom-120,
  .padding-bottom-80 {
    padding-bottom: 50px;
  }

  .topBar {
    display: none;
  }

  .loginBtn {
    font-size: 19px;
    padding: 6px 16px;
    line-height: 23px;
  }

  .mobileNav {
    display: block;
  }

  .aboutBox img {
    margin-bottom: 30px;
  }

  .mettingImg {
    position: relative;
    bottom: 0px;
    margin-bottom: 30px;
  }

  .eventsDtls {
    padding-left: 0px;
    margin-bottom: 0px;
  }

  .carousel-item .carousel-caption {
    top: 15%;
    padding: 15px;
  }

  .carousel-item img {
    height: 400px;
  }

  .carousel-item .carousel-caption h3 {
    font-size: 26px;
    line-height: 36px;
  }

  .carousel-item .carousel-caption p {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }

  .bannerSrchBtn img {
    height: 40px;
  }

  .footer-widget {
    margin-bottom: 30px;
  }

  .inner-footer .colBox1 {
    width: 100%;
  }

  .footer-widget h4 {
    margin-bottom: 15px;
  }

  .inner-footer {
    gap: 10px;
    flex-direction: column;
  }

  .copyRight {
    text-align: left;
    margin: 0;
  }

  .navbar-nav .active::after {
    margin: 0;
    bottom: -2px;
  }

  .eventBanner {
    padding: 50px 0px;
  }

  .logoBox {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .eventBanner h3 {
    font-size: 36px;
    margin-bottom: 30px;
  }

  .event-search-banner {
    flex-direction: column;
    gap: 0;
  }

}