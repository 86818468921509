/* AdminLayout.css */
.admin-layout {
    display: flex;
    height: 100vh;
    background-color: #1C232B;
}

.admin-logo {
    padding: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid hsl(0deg 0% 100% / 20%);
}

.admin-side-menu {
    width: 249px;
    color: #fff;
    background: #141B22;
    border-radius: 0px 40px 40px 0px;
}

.admin-content {
    width: 100%;
    overflow-y: scroll;
    padding: 20px 30px 20px 20px;
}

.menuBox {
    padding-left: 20px;
}

.menuBox .nav-link {
    padding: 10px;
}

button.accordion-button.collapsed {
    color: #fff;
}

.accordion,
.accordion-item,
button.accordion-button.collapsed,
.accordion-header,
.accordion-item:last-of-type,
.accordion-item:first-of-type .accordion-button,
.accordion-body {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none;
    padding-left: 0;
}

.menuBox .accordion-button,
.menuBox .accordion-button[aria-expanded="true"] {
    background-color: #ff8811 !important;
    border-radius: 10px 0px 0px 10px !important;
    border: none !important;
    box-shadow: none !important;
    color: #fff !important;
    font-size: 16px !important;
    font-weight: 500;
    padding: 14px 10px !important;
}

.menuBox .dropdown-item {
    color: #fff !important;
    line-height: 36px;
    font-size: 16px;
    font-weight: 500 !important;
    letter-spacing: 0.5px;
}

.menuBox .accordion-body {
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 5px;
    padding-left: 35px;
}

.accordion-button img {
    margin-right: 10px;
    height: 20px;
}

.menuBox .nav-link img {
    margin-right: 10px;
    height: 20px;
}

/* Add styles for the active Nav.Link */
.menuBox .dropdown-item.active,
.menuBox .nav-link.active {
    color: #ff8811 !important;
    /* Change the color to yellow for the active link */
}

.adminEventSearch {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.adminEventSearch input {
    background: transparent url(../../Assets/images/input-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 80px;
    min-width: 304px;
    height: 50px;
    padding: 15px 15px 15px 50px;
    color: #bcbcbc;
    border: none;
    position: relative;
    box-shadow: 0px 4px 8px 0px #FFFFFF1A;
}

.adminEventSearch img {
    position: absolute;
    top: 9px;
    z-index: 9;
    left: 5px;
}

.adminEventSearch .themeBtn {
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    padding: 10px 70px;
    margin-bottom: 5px;
    border-radius: 50px;
    display: inline-block;
    background-color: #FF8811;
    font-family: 'Akaya Kanadaka';
}

.adminEventSearch .themeBtn:hover {
    color: #fff;
}

.ongoingEventSection .eventBox {
    padding: 12px;
    min-height: 340px;
    border-radius: 20px;
}

.ongoingEventSection .eventBox .adminEventImg {
    height: 122px;
    min-height: 122px;
    object-fit: cover;
    border-radius: 10px;
    object-position: top;
}

.eventNameBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0px;
}

.eventNameBox .adminEventName h4 {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: #FF8811;
    font-family: 'Akaya Kanadaka';
}

.eventStatus .statusBox {
    width: 88px;
    height: 26px;
    font-size: 14px;
    color: #EB5757;
    font-weight: 700;
    text-align: center;
    line-height: 26px;
    border-radius: 17.5px;
    background: #EB575733;
    font-family: 'Quicksand', sans-serif;
}

.adminEventDtls p {
    font-family: 'Quicksand', sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
}

.ongoingEventSection .eventBox .eventEditBtn {
    position: absolute;
    right: 20px;
    top: 20px;
    border: none;
    background: #fff;
    border-radius: 50px;
    width: 24px;
    height: 24px;
    line-height: 10px;
    text-align: center;
    padding: 0;
}

.ongoingEventSection .eventBox .eventEditBtn img {
    width: 14px;
    height: 14px;
    min-height: auto;
    object-fit: contain;
}

.adminTag {
    display: flex;
    gap: 10px;
}

.adminTag div {
    height: 26px;
    font-size: 14px;
    min-width: 88px;
    color: #FF8811;
    line-height: 26px;
    text-align: center;
    margin-bottom: 5px;
    border-radius: 50px;
    background-color: #FF881133;
}

.openFormsBtn {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    padding: 2px 22px;
    margin-bottom: 5px;
    border-radius: 50px;
    display: inline-block;
    background-color: #FF8811;
    font-family: 'Akaya Kanadaka';
    border: none;
    margin-top: 20px;
}

.categoryAddButton {
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.overViewCountBox {
    display: flex;
    gap: 20px;
    width: 100%;
    padding: 20px;
    color: #fff;
    border-radius: 14px;
    margin-bottom: 30px;
    font-family: 'Quicksand', sans-serif;
    box-shadow: 0px 4px 8px 0px #FFFFFF1A;
    background: linear-gradient(309deg, #27394B 2.37%, rgba(20, 27, 34, 0.43) 30.68%);
}

.overViewCountBox h4 {
    width: 100px;
    font-size: 24px;
    font-weight: 600;
    line-height: 38px;
    letter-spacing: 0.5px;
    font-family: 'Quicksand', sans-serif;
}

.overViewCountBox .countNo {
    font-size: 42px;
    font-weight: 700;
    font-family: 'Quicksand', sans-serif;
    width: 100%;
}

.walletBoxHeader {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.walletBoxHeader h5 {
    font-family: 'Quicksand', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
}

.walletBoxHeader h3 {
    margin: 0;
    font-size: 50px;
    font-weight: 400;
    line-height: 60px;
    font-family: 'Akaya Kanadaka';
}

.availableAmtBox {
    gap: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-radius: 50px;
    background: #141B22;
    padding: 12px 15px 12px 30px;
    border: 2px solid rgb(233 211 193 / 44%);
    box-shadow: 0px 4px 8px 0px #FFFFFF1A;
}

.availableAmtBox input {
    width: 100%;
    border: none;
    border-radius: 50px;
    padding: 10px 15px;
    color: #fff;
    background: rgb(255 255 255 / 14%);
}


.walletBtnBox .themeBtn {
    width: 100%;
    color: #fff;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    text-align: center;
    padding: 12px 70px;
    margin-bottom: 20px;
    border-radius: 50px;
    display: inline-block;
    background-color: #FF8811;
    font-family: 'Akaya Kanadaka';
}

.recentEvents {
    background: #1C232B;
    border-radius: 14px;
    border: 1px solid #414141;
    box-shadow: 0px 4px 8px 0px #FFFFFF1A;
}

.recentEvents .tableHead {
    color: #fff;
    padding: 12.5px 30px;
    background-color: #FF8811;
    border-radius: 14px 14px 0px 0px;

    font-family: 'Quicksand', sans-serif;
    font-size: 26px;
    font-weight: 700;
}

.recentEvents th.ant-table-cell {
    border-radius: 0px !important;
}