.about {
    background-color: #1c232b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.about .div {
    background-color: var(--primary-bg);
    height: 2209px;
    overflow: hidden;
    position: relative;
    width: 1440px;
}

.about .overlap {
    height: 482px;
    left: -48px;
    position: absolute;
    top: 0;
    width: 1650px;
}

.about .rectangle {
    height: 383px;
    left: 48px;
    object-fit: cover;
    position: absolute;
    top: 99px;
    width: 1440px;
}

.about .about-us {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 50px;
    font-weight: 700;
    left: 659px;
    letter-spacing: 0;
    line-height: 55.8px;
    position: absolute;
    top: 207px;
    white-space: nowrap;
}

.about .text-wrapper {
    color: #ffffff;
    font-family: "Quicksand", Helvetica;
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 55.8px;
}

.about .flexcontainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: 80px;
    left: 393px;
    position: absolute;
    top: 293px;
    width: 751px;
}

.about .text {
    align-self: stretch;
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 33.5px;
    position: relative;
    text-align: center;
}

.about .span {
    color: #ffffff;
    font-family: "Quicksand", Helvetica;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 33.5px;
}

.about .ellipse {
    background: linear-gradient(180deg, rgb(255, 136, 17) 0%, rgba(28, 35, 43, 0) 100%);
    border-radius: 126px;
    height: 252px;
    left: 1398px;
    position: absolute;
    top: 227px;
    width: 252px;
}

.about .ellipse-2 {
    background: linear-gradient(180deg, rgb(255, 136, 17) 0%, rgba(28, 35, 43, 0) 100%);
    border-radius: 48.49px;
    height: 97px;
    left: 18px;
    position: absolute;
    top: 313px;
    transform: rotate(120deg);
    width: 97px;
}

.about .group {
    background-color: #1c232b;
    height: 100px;
    left: 48px;
    position: absolute;
    top: 0;
    width: 1440px;
}

.about .overlap-group-wrapper {
    height: 50px;
    left: 1098px;
    position: absolute;
    top: 25px;
    width: 246px;
}

.about .overlap-group {
    background-color: #ffffff;
    border-radius: 28px;
    height: 50px;
    position: relative;
    width: 242px;
}

.about .div-wrapper {
    background-color: #ff8811;
    border-radius: 28px;
    height: 41px;
    left: 6px;
    position: absolute;
    top: 4px;
    width: 119px;
}

.about .text-wrapper-2 {
    color: #ffff;
    font-family: "Akaya Kanadaka", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 29px;
    left: 34px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 6px;
}

.about .text-wrapper-3 {
    color: var(--primary-bg);
    font-family: "Akaya Kanadaka", Helvetica;
    font-size: 24px;
    font-weight: 400;
    height: 29px;
    left: 146px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 10px;
}

.about .logo-without-bg {
    height: 50px;
    left: 100px;
    position: absolute;
    top: 25px;
    width: 35px;
}

.about .navbar {
    height: 29px;
    left: 510px;
    position: absolute;
    top: 39px;
    width: 444px;
}

.about .rectangle-2 {
    background-color: var(--main);
    border-radius: 15px;
    height: 2px;
    left: 82px;
    position: absolute;
    top: 27px;
    width: 52px;
}

.about .text-wrapper-4 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.about .text-wrapper-5 {
    color: var(--main);
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 600;
    left: 82px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.about .text-wrapper-6 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 166px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.about .text-wrapper-7 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 254px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.about .text-wrapper-8 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 341px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.about .footer {
    background-color: transparent;
    height: 359px;
    left: 0;
    position: absolute;
    top: 1850px;
    width: 1452px;
}

.about .overlap-2 {
    background-color: #141b22;
    height: 359px;
    position: relative;
    width: 1440px;
}

.about .img {
    height: 50px;
    left: 100px;
    position: absolute;
    top: 100px;
    width: 35px;
}

.about .login-register {
    color: #ffff;
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 573px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 147px;
}

.about .text-wrapper-9 {
    color: var(--main);
    font-family: "DM Sans", Helvetica;
    font-size: 18px;
    font-weight: 700;
    left: 573px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 100px;
}

.about .p {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 100px;
    letter-spacing: 0;
    line-height: 22.4px;
    position: absolute;
    top: 166px;
    width: 333px;
}

.about .facebook {
    height: 36px;
    left: 1252px;
    position: absolute;
    top: 100px;
    width: 36px;
}

.about .instagram {
    background-color: #1c232b;
    border-radius: 18px;
    height: 36px;
    left: 1304px;
    position: absolute;
    top: 100px;
    width: 36px;
}

.about .instagram-icon {
    height: 18px;
    left: 9px;
    position: absolute;
    top: 9px;
    width: 18px;
}

.about .about-us-press {
    color: #ffff;
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 914px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 147px;
}

.about .text-wrapper-10 {
    color: var(--main);
    font-family: "DM Sans", Helvetica;
    font-size: 18px;
    font-weight: 700;
    left: 914px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 100px;
}

.about .text-wrapper-11 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 1193px;
    letter-spacing: 0;
    line-height: 22.4px;
    position: absolute;
    top: 155px;
    white-space: nowrap;
}

.about .group-2 {
    height: 36px;
    left: 1149px;
    position: absolute;
    top: 100px;
    width: 36px;
}

.about .vector-wrapper {
    background-color: #1c232b;
    border-radius: 18px;
    height: 36px;
    left: 1201px;
    position: absolute;
    top: 100px;
    width: 36px;
}

.about .vector {
    height: 19px;
    left: 8px;
    position: absolute;
    top: 9px;
    width: 20px;
}

.about .group-3 {
    height: 164px;
    left: 100px;
    position: absolute;
    top: 1586px;
    width: 1239px;
}

.about .flexcontainer-2 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 155px;
    left: 0;
    position: absolute;
    top: 0;
    width: 530px;
}

.about .text-2 {
    align-self: stretch;
    color: #ffff;
    font-family: "Akaya Kanadaka", Helvetica;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 44.6px;
    position: relative;
}

.about .text-wrapper-12 {
    color: #ffffff;
    font-family: "Akaya Kanadaka", Helvetica;
    font-size: 40px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 44.6px;
}

.about .flexcontainer-3 {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: 164px;
    left: 621px;
    position: absolute;
    top: 0;
    width: 614px;
}

.about .span-wrapper {
    align-self: stretch;
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30px;
    position: relative;
}

.about .text-wrapper-13 {
    color: #bcbcbc;
    font-family: "Quicksand", Helvetica;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30px;
}

.about .overlap-wrapper {
    background-color: #141b22;
    height: 904px;
    left: 0;
    position: absolute;
    top: 582px;
    width: 1440px;
}

.about .overlap-3 {
    height: 776px;
    left: 64px;
    position: relative;
    top: 64px;
    width: 1312px;
}

.about .overlap-4 {
    height: 776px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1312px;
}

.about .rectangle-3 {
    height: 400px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 676px;
}

.about .rectangle-4 {
    height: 400px;
    left: 636px;
    object-fit: cover;
    position: absolute;
    top: 376px;
    width: 676px;
}

.about .group-4 {
    height: 310px;
    left: 689px;
    position: absolute;
    top: 45px;
    width: 591px;
}

.about .text-wrapper-14 {
    color: #ffff;
    font-family: "Akaya Kanadaka", Helvetica;
    font-size: 40px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 60px;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.about .text-wrapper-15 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 20px;
    font-weight: 400;
    left: 0;
    letter-spacing: 0;
    line-height: 30px;
    position: absolute;
    top: 70px;
    width: 587px;
}

.about .group-5 {
    height: 310px;
    left: 36px;
    position: absolute;
    top: 421px;
    width: 591px;
}