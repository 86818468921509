.event-detail {
    background-color: #1c232b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
}

.event-detail .div {
    background-color: #1c232b;
    height: 1728px;
    overflow: hidden;
    position: relative;
    width: 1440px;
}

.event-detail .overlap {
    height: 542px;
    left: -48px;
    position: absolute;
    top: 0;
    width: 1530px;
}

.event-detail .rectangle {
    height: 383px;
    left: 48px;
    position: absolute;
    top: 99px;
    width: 1440px;
}

.event-detail .group {
    height: 50px;
    left: 641px;
    position: absolute;
    top: 357px;
    width: 255px;
}

.event-detail .overlap-group {
    -webkit-backdrop-filter: blur(13px) brightness(100%);
    backdrop-filter: blur(13px) brightness(100%);
    background-color: #ffffff1a;
    border: 1px solid;
    border-color: #ff8811;
    border-radius: 50px;
    height: 50px;
    position: relative;
    width: 253px;
}

.event-detail .text-wrapper {
    color: #ffff;
    font-family: "Akaya Kanadaka", Helvetica;
    font-size: 24px;
    font-weight: 400;
    left: 17px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 9px;
}

.event-detail .flexcontainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 14px;
    height: 80px;
    left: 392px;
    position: absolute;
    top: 256px;
    width: 751px;
}

.event-detail .text {
    align-self: stretch;
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 33.5px;
    position: relative;
    text-align: center;
}

.event-detail .span {
    color: #ffffff;
    font-family: "Quicksand", Helvetica;
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 33.5px;
}

.event-detail .text-wrapper-2 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 50px;
    font-weight: 700;
    height: 63px;
    left: 620px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    text-align: center;
    top: 173px;
}

.event-detail .ellipse {
    background: linear-gradient(180deg, rgb(255, 136, 17) 0%, rgba(28, 35, 43, 0) 100%);
    border-radius: 82px;
    height: 164px;
    left: 1366px;
    position: absolute;
    top: 378px;
    width: 164px;
}

.event-detail .ellipse-2 {
    background: linear-gradient(180deg, rgb(255, 136, 17) 0%, rgba(28, 35, 43, 0) 100%);
    border-radius: 48.49px;
    height: 97px;
    left: 18px;
    position: absolute;
    top: 313px;
    transform: rotate(120deg);
    width: 97px;
}

.event-detail .group-2 {
    background-color: #1c232b;
    height: 100px;
    left: 48px;
    position: absolute;
    top: 0;
    width: 1440px;
}

.event-detail .img {
    height: 50px;
    left: 1290px;
    object-fit: cover;
    position: absolute;
    top: 25px;
    width: 50px;
}

.event-detail .logo-without-bg {
    height: 50px;
    left: 100px;
    position: absolute;
    top: 25px;
    width: 35px;
}

.event-detail .navbar {
    height: 29px;
    left: 510px;
    position: absolute;
    top: 39px;
    width: 444px;
}

.event-detail .rectangle-2 {
    background-color: #ffff;
    border-radius: 15px;
    height: 2px;
    left: 166px;
    position: absolute;
    top: 27px;
    width: 57px;
}

.event-detail .text-wrapper-3 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 0;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.event-detail .text-wrapper-4 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 82px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.event-detail .text-wrapper-5 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 600;
    left: 166px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.event-detail .text-wrapper-6 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 254px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.event-detail .text-wrapper-7 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 18px;
    font-weight: 500;
    left: 341px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
}

.event-detail .footer {
    background-color: transparent;
    height: 359px;
    left: 0;
    position: absolute;
    top: 1369px;
    width: 1452px;
}

.event-detail .overlap-2 {
    background-color: #141b22;
    height: 359px;
    position: relative;
    width: 1440px;
}

.event-detail .logo-without-bg-2 {
    height: 50px;
    left: 100px;
    position: absolute;
    top: 100px;
    width: 35px;
}

.event-detail .login-register {
    color: #ffff;
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 573px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 147px;
}

.event-detail .text-wrapper-8 {
    color: #ffff;
    font-family: "DM Sans", Helvetica;
    font-size: 18px;
    font-weight: 700;
    left: 573px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 100px;
}

.event-detail .p {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 100px;
    letter-spacing: 0;
    line-height: 22.4px;
    position: absolute;
    top: 166px;
    width: 333px;
}

.event-detail .facebook {
    height: 36px;
    left: 1252px;
    position: absolute;
    top: 100px;
    width: 36px;
}

.event-detail .instagram {
    background-color: #1c232b;
    border-radius: 18px;
    height: 36px;
    left: 1304px;
    position: absolute;
    top: 100px;
    width: 36px;
}

.event-detail .instagram-icon {
    height: 18px;
    left: 9px;
    position: absolute;
    top: 9px;
    width: 18px;
}

.event-detail .about-us-press {
    color: #ffff;
    font-family: "DM Sans", Helvetica;
    font-size: 14px;
    font-weight: 500;
    left: 914px;
    letter-spacing: 0;
    line-height: 28px;
    position: absolute;
    top: 147px;
}

.event-detail .text-wrapper-9 {
    color: #ffff;
    font-family: "DM Sans", Helvetica;
    font-size: 18px;
    font-weight: 700;
    left: 914px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 100px;
}

.event-detail .text-wrapper-10 {
    color: #ffff;
    font-family: "Quicksand", Helvetica;
    font-size: 14px;
    font-weight: 400;
    left: 1193px;
    letter-spacing: 0;
    line-height: 22.4px;
    position: absolute;
    top: 155px;
    white-space: nowrap;
}

.event-detail .group-3 {
    height: 36px;
    left: 1149px;
    position: absolute;
    top: 100px;
    width: 36px;
}

.event-detail .vector-wrapper {
    background-color: #1c232b;
    border-radius: 18px;
    height: 36px;
    left: 1201px;
    position: absolute;
    top: 100px;
    width: 36px;
}

.event-detail .vector {
    height: 19px;
    left: 8px;
    position: absolute;
    top: 9px;
    width: 20px;
}

.event-detail .overlap-3 {
    height: 696px;
    left: 51px;
    position: absolute;
    top: 581px;
    width: 978px;
}

.event-detail .overlap-4 {
    height: 696px;
    left: 0;
    position: absolute;
    top: 0;
    width: 741px;
}

.event-detail .text-wrapper-11 {
    color: #ffff;
    font-family: "Akaya Kanadaka", Helvetica;
    font-size: 40px;
    font-weight: 400;
    left: 598px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 0;
    white-space: nowrap;
}

.event-detail .overlap-5 {
    height: 656px;
    left: 0;
    position: absolute;
    top: 40px;
    width: 660px;
}

.event-detail .group-4 {
    height: 384px;
    left: 0;
    position: absolute;
    top: 0;
    width: 384px;
}

.event-detail .group-5 {
    height: 300px;
    left: 42px;
    position: absolute;
    top: 356px;
    width: 300px;
}

.event-detail .group-6 {
    height: 300px;
    left: 360px;
    position: absolute;
    top: 42px;
    width: 300px;
}

.event-detail .group-7 {
    height: 300px;
    left: 360px;
    position: absolute;
    top: 356px;
    width: 300px;
}

.event-detail .group-8 {
    height: 300px;
    left: 678px;
    position: absolute;
    top: 82px;
    width: 300px;
}

.event-detail .group-9 {
    height: 300px;
    left: 678px;
    position: absolute;
    top: 396px;
    width: 300px;
}

.event-detail .group-10 {
    height: 300px;
    left: 1047px;
    position: absolute;
    top: 663px;
    width: 300px;
}

.event-detail .group-11 {
    height: 300px;
    left: 1047px;
    position: absolute;
    top: 977px;
    width: 300px;
}